.table-zebra tbody tr td {
    background-color: #FFD65C;
}

.table-zebra tbody tr:nth-child(2n) td {
    background-color: #FFCA28;
}

table td:nth-child(2) {
    text-align: right;
    white-space: nowrap;
}

.service-info-bg {
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.1);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
}

.max-size-32px {
    width: 32px;
}

.max-width-96 {
    max-width: 96px;
}