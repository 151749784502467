.fillScreen {
    position:relative;
    top:0;
    left:0;
    height:100%;
    width:100%;
    background:red;
}

.hero {
    background: url('../img/bg3.jpeg') no-repeat;
    width:100%;
    height:100%;
    background-position: 10% 70%;

}

.hero-mob {
    background: url('../img/bg3.jpeg') no-repeat;
    width:100%;
    height:100%;
    background-position: 30% 60%;

}


.morphism-hero {
    background: rgba( 255, 255, 255, 0.80 );
}

.bg-opacity-85 {
    --tw-bg-opacity: 85%;
}

.h-600 {
    height:"600px";
}

.h-95vh {
    height: 95vh;
}
