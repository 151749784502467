/* Light mode */
@media (prefers-color-scheme: light) {
    body {
        background-color: white;
        color: black;
    }
}

/* Dark mode */
@media (prefers-color-scheme: dark) {
    body {
        background-color: white;
        color: black;
    }
}

.popup {
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    z-index: 1000;
    width: 100%;
    background-color: rgba(84, 109, 155, 0.9);
    opacity: 0;
    visibility: hidden;
    transition:500ms all;

}

.popup.is-visible {
    opacity: 1;
    visibility: visible;
    transition:1s all;
}

.container-mob{
    width: 60%;
}

.popup-container {
    transform:translateY(-50%);
    transition:100ms all;
    position: relative;
    width: 60%;
    margin: 1em auto;
    top: 5%;
    padding:2rem;
    background: rgb(255, 255, 255);
    border-radius: .25em .25em .4em .4em;
    text-align: center;
}

.is-visible .popup-container {
    transform:translateY(0);
    transition:100ms all;
}

.popup-container .popup-close {
    position: absolute;
    top: 8px;
    font-size:0;
    right: 8px;
    width: 30px;
    height: 30px;
}


.popup-container .popup-close::before,
.popup-container .popup-close::after {
    content: '';
    position: absolute;
    top: 12px;
    width: 14px;
    height: 3px;
    background-color: #000000;
}

.popup-container .popup-close::before {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    left: 8px;
}

.popup-container .popup-close::after {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    right: 8px;
}
